"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formFunctionMapping = exports.createRequestEndDate = exports.percent = exports.divide = void 0;
const enum_1 = require("../enum");
const period_1 = require("../enum/period");
const divide = (formData, keys) => {
  const [num1Key, num2Key] = keys;
  let value = 0;
  if (formData[num1Key] && formData[num2Key]) {
    value = +formData[num1Key] / +formData[num2Key];
  }
  return {
    value,
    disabled: true
  };
};
exports.divide = divide;
const percent = (formData, keys) => {
  const [num1Key, num2Key] = keys;
  let value = 0;
  if (formData[num1Key] && formData[num2Key]) {
    value = +formData[num2Key] * 100 / +formData[num1Key];
  }
  return {
    value,
    disabled: true
  };
};
exports.percent = percent;
const createRequestEndDate = (formData, keys) => {
  let value = null;
  const reportingPeriod = formData[keys[0]];
  if (reportingPeriod && formData['startDate']) {
    switch (reportingPeriod) {
      case period_1.ReportingPeriod.MONTHLY:
        value = new Date(formData['startDate']);
        value.setMonth(value.getMonth() + 1);
        break;
      case period_1.ReportingPeriod.QUARTERLY:
        value = new Date(formData['startDate']);
        value.setMonth(value.getMonth() + 3);
        break;
      case period_1.ReportingPeriod.YEARLY:
        value = new Date(formData['startDate']);
        value.setFullYear(value.getFullYear() + 1);
        break;
      case period_1.ReportingPeriod.HALF_YEARLY:
        value = new Date(formData['startDate']);
        value.setMonth(value.getMonth() + 6);
        break;
    }
  }
  return {
    value,
    disabled: true
  };
};
exports.createRequestEndDate = createRequestEndDate;
exports.formFunctionMapping = {
  [enum_1.FormOnChangeFunctions.DIVIDE]: exports.divide,
  [enum_1.FormOnChangeFunctions.PERCENT]: exports.percent,
  [enum_1.FormOnChangeFunctions.CNR_END_DATE]: exports.createRequestEndDate
};
