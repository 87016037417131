"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnergyTypeReduction = exports.EnergyReductionSource = exports.EnergyBasisCalculation = exports.BusinessMetric = exports.EnergyActivityCategories = exports.EnergyDownstream = exports.EnergyUpstream = exports.EnergyActivity = exports.EnergyType = exports.EnergyOperation = exports.MaritalStatus = exports.Religion = exports.SocioeconomicStatus = exports.LGBTQIdentity = exports.VeteranStatus = exports.IndigenousStatus = exports.Nationality = exports.EducationalLevel = exports.Disability = exports.Ethnicity = exports.EmployeeCategory = exports.DiversityIndicators = exports.AgeGroups = exports.GovernanceBodyCategories = exports.DisposalOperation = exports.SiteLocation = exports.RecoveryOperation = exports.WasteComposition = exports.WasteSource = exports.WasteType = exports.WasteMaterial = exports.EmployeeFunction = exports.EmployeeLevel = exports.IllHealthTypes = exports.InjurySeverities = exports.InjuryTypes = exports.Genders = exports.WorkerTypes = exports.AuditTypes = exports.WorkerCategories = void 0;
var WorkerCategories;
(function (WorkerCategories) {
  WorkerCategories["EMPLOYEE"] = "EMPLOYEE";
  WorkerCategories["CONTROLLED_WORKER"] = "CONTROLLED-WORKER";
})(WorkerCategories || (exports.WorkerCategories = WorkerCategories = {}));
var AuditTypes;
(function (AuditTypes) {
  AuditTypes["INTERNAL"] = "INTERNAL";
  AuditTypes["EXTERNAL"] = "EXTERNAL";
})(AuditTypes || (exports.AuditTypes = AuditTypes = {}));
var WorkerTypes;
(function (WorkerTypes) {
  WorkerTypes["EMPLOYEE"] = "EMPLOYEE";
  WorkerTypes["NON_EMPLOYEE"] = "NON-EMPLOYEE";
})(WorkerTypes || (exports.WorkerTypes = WorkerTypes = {}));
var Genders;
(function (Genders) {
  Genders["MALE"] = "MALE";
  Genders["FEMALE"] = "FEMALE";
  Genders["OTHERS"] = "OTHERS";
})(Genders || (exports.Genders = Genders = {}));
var InjuryTypes;
(function (InjuryTypes) {
  InjuryTypes["FATALITY"] = "FATALITY";
  InjuryTypes["HIGH_CONSEQUENCE"] = "HIGH-CONSEQUENCE";
  InjuryTypes["RECORDABLE"] = "RECORDABLE";
})(InjuryTypes || (exports.InjuryTypes = InjuryTypes = {}));
var InjurySeverities;
(function (InjurySeverities) {
  InjurySeverities["MILD"] = "MILD";
  InjurySeverities["MODERATE"] = "MODERATE";
  InjurySeverities["SEVERE"] = "SEVERE";
  InjurySeverities["FATAL"] = "FATAL";
})(InjurySeverities || (exports.InjurySeverities = InjurySeverities = {}));
var IllHealthTypes;
(function (IllHealthTypes) {
  IllHealthTypes["RESPIRATORY_CONDITIONS"] = "RESPIRATORY_CONDITIONS";
  IllHealthTypes["MUSCULOSKELETAL_DISORDERS"] = "MUSCULOSKELETAL_DISORDERS";
  IllHealthTypes["SKIN_DISEASES"] = "SKIN_DISEASES";
  IllHealthTypes["HEARING_LOSS"] = "HEARING_LOSS";
  IllHealthTypes["PSYCHOLOGICAL_DISORDERS"] = "PSYCHOLOGICAL_DISORDERS";
  IllHealthTypes["CARDIOVASCULAR_DISORDERS"] = "CARDIOVASCULAR_DISEASES";
  IllHealthTypes["GASTROINTESTINAL_DISORDERS"] = "GASTROINTESTINAL_DISORDERS";
  IllHealthTypes["VISION_IMPAIRMENT"] = "VISION_IMPAIRMENT";
  IllHealthTypes["OCCUPATIONAL_DISORDERS"] = "OCCUPATIONAL_CANCERS";
  IllHealthTypes["INFECTIOUS_DISORDERS"] = "INFECTIOUS_DISEASES";
})(IllHealthTypes || (exports.IllHealthTypes = IllHealthTypes = {}));
var EmployeeLevel;
(function (EmployeeLevel) {
  EmployeeLevel["EXECUTIVE_LEVEL"] = "EXECUTIVE_LEVEL";
  EmployeeLevel["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  EmployeeLevel["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  EmployeeLevel["JUNIOR_MANAGEMENT"] = "JUNIOR_MANAGEMENT";
  EmployeeLevel["PROFESSIONAL_STAFF"] = "PROFESSIONAL_STAFF";
  EmployeeLevel["TECHNICAL_STAFF"] = "TECHNICAL_STAFF";
  EmployeeLevel["ADMINISTRATIVE_STAFF"] = "ADMINISTRATIVE_STAFF";
  EmployeeLevel["OPERATIONAL_STAFF"] = "OPERATIONAL_STAFF";
  EmployeeLevel["ENTRY_LEVEL_STAFF"] = "ENTRY_LEVEL_STAFF";
})(EmployeeLevel || (exports.EmployeeLevel = EmployeeLevel = {}));
var EmployeeFunction;
(function (EmployeeFunction) {
  EmployeeFunction["TECHNICAL"] = "TECHNICAL";
  EmployeeFunction["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  EmployeeFunction["PRODUCTION"] = "PRODUCTION";
  EmployeeFunction["SALES"] = "SALES";
  EmployeeFunction["MARKETING"] = "MARKETING";
  EmployeeFunction["HUMAN_RESOURCES"] = "HUMAN_RESOURCES";
  EmployeeFunction["FINANCE"] = "FINANCE";
  EmployeeFunction["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
  EmployeeFunction["RESEARCH_AND_DEVELOPMENT"] = "RESEARCH_AND_DEVELOPMENT";
  EmployeeFunction["IT_SUPPORT"] = "IT_SUPPORT";
  EmployeeFunction["LEGAL"] = "LEGAL";
  EmployeeFunction["LOGISTICS"] = "LOGISTICS";
})(EmployeeFunction || (exports.EmployeeFunction = EmployeeFunction = {}));
var WasteMaterial;
(function (WasteMaterial) {
  WasteMaterial["FABRIC"] = "Fabric";
  WasteMaterial["USED_OIL"] = "Used Oil";
  WasteMaterial["PACKAGING_WASTE"] = "Packaging Waste";
  WasteMaterial["BATTERIES"] = "Batteries";
  WasteMaterial["OTHER"] = "Other (values to be specified later)";
})(WasteMaterial || (exports.WasteMaterial = WasteMaterial = {}));
var WasteType;
(function (WasteType) {
  WasteType["HAZARDOUS"] = "Hazardous";
  WasteType["NON_HAZARDOUS"] = "Non-Hazardous";
})(WasteType || (exports.WasteType = WasteType = {}));
var WasteSource;
(function (WasteSource) {
  WasteSource["OWN_ACTIVITIES"] = "Own Activities";
  WasteSource["UPSTREAM_ACTIVITIES"] = "Upstream Activities";
  WasteSource["DOWNSTREAM_ACTIVITIES"] = "Downstream Activities";
})(WasteSource || (exports.WasteSource = WasteSource = {}));
var WasteComposition;
(function (WasteComposition) {
  WasteComposition["BIOMASS"] = "Biomass";
  WasteComposition["PLASTICS"] = "Plastics";
  WasteComposition["TEXTILES"] = "Textiles";
  WasteComposition["METALS"] = "Metals";
  WasteComposition["MON_METALLIC_MINERALS"] = "Mon-metallic minerals";
})(WasteComposition || (exports.WasteComposition = WasteComposition = {}));
var RecoveryOperation;
(function (RecoveryOperation) {
  RecoveryOperation["REUSE"] = "Reuse";
  RecoveryOperation["RECYCLE"] = "Recycle";
  RecoveryOperation["OTHER"] = "Other ";
})(RecoveryOperation || (exports.RecoveryOperation = RecoveryOperation = {}));
var SiteLocation;
(function (SiteLocation) {
  SiteLocation["ONSITE"] = "Onsite";
  SiteLocation["OFFSITE"] = "Offsite";
})(SiteLocation || (exports.SiteLocation = SiteLocation = {}));
var DisposalOperation;
(function (DisposalOperation) {
  DisposalOperation["INCINERATION_WITH_ENERGY_RECOVERY"] = "Incineration (with energy recovery)";
  DisposalOperation["INCINERATION_WITHOUT_ENERGY_RECOVERY"] = "Incineration (without energy recovery)";
  DisposalOperation["LAND_FILLING"] = "Land filling";
  DisposalOperation["DUMPING"] = "Dumping";
  DisposalOperation["OPEN_BURNING"] = "Open Burning";
  DisposalOperation["DEEP_WELL_INJECTION"] = "Deep Well Injection";
  DisposalOperation["OTHER"] = "Other ";
})(DisposalOperation || (exports.DisposalOperation = DisposalOperation = {}));
var GovernanceBodyCategories;
(function (GovernanceBodyCategories) {
  GovernanceBodyCategories["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  GovernanceBodyCategories["EXECUTIVE_MANAGEMENT_TEAM"] = "EXECUTIVE_MANAGEMENT_TEAM";
  GovernanceBodyCategories["ADVISORY_COMMITTEES"] = "ADVISORY_COMMITTEES";
  GovernanceBodyCategories["AUDIT_COMMITTEE"] = "AUDIT_COMMITTEE";
  GovernanceBodyCategories["COMPENSATION_COMMITTEE"] = "COMPENSATION_COMMITTEE";
  GovernanceBodyCategories["NOMINATIONS_COMMITTEE"] = "NOMINATIONS_COMMITTEE";
  GovernanceBodyCategories["CORPORATE_GOVERNANCE_COMMITTEE"] = "CORPORATE_GOVERNANCE_COMMITTEE";
  GovernanceBodyCategories["ETHICS_OR_COMPLIANCE_COMMITTEE"] = "ETHICS_OR_COMPLIANCE_COMMITTEE";
  GovernanceBodyCategories["RISK_MANAGEMENT_COMMITTEE"] = "RISK_MANAGEMENT_COMMITTEE";
  GovernanceBodyCategories["SUSTAINABILITY_CSR_COMMITTEE"] = "SUSTAINABILITY_CSR_COMMITTEE";
  GovernanceBodyCategories["SPECIALIZED_COMMITTEES"] = "SPECIALIZED_COMMITTEES";
})(GovernanceBodyCategories || (exports.GovernanceBodyCategories = GovernanceBodyCategories = {}));
var AgeGroups;
(function (AgeGroups) {
  AgeGroups["UNDER"] = "UNDER";
  AgeGroups["MID"] = "MID";
  AgeGroups["OVER"] = "OVER";
})(AgeGroups || (exports.AgeGroups = AgeGroups = {}));
var DiversityIndicators;
(function (DiversityIndicators) {
  DiversityIndicators["ETHNICITY"] = "ETHNICITY";
  DiversityIndicators["DISABILITY"] = "DISABILITY";
  DiversityIndicators["EDUCATIONAL_LEVEL"] = "EDUCATIONAL_LEVEL";
  DiversityIndicators["NATIONALITY"] = "NATIONALITY";
  DiversityIndicators["INDIGENOUS_STATUS"] = "INDIGENOUS_STATUS";
  DiversityIndicators["VETERAN_STATUS"] = "VETERAN_STATUS";
  DiversityIndicators["LGBTQ_IDENTITY"] = "LGBTQ_IDENTITY";
  DiversityIndicators["SOCIOECONOMIC_STATUS"] = "SOCIOECONOMIC_STATUS";
  DiversityIndicators["RELIGION"] = "RELIGION";
  DiversityIndicators["MARITAL_STATUS"] = "MARITAL_STATUS";
})(DiversityIndicators || (exports.DiversityIndicators = DiversityIndicators = {}));
var EmployeeCategory;
(function (EmployeeCategory) {
  EmployeeCategory["SENIOR_MANAGEMENT"] = "SENIOR_MANAGEMENT";
  EmployeeCategory["MIDDLE_MANAGEMENT"] = "MIDDLE_MANAGEMENT";
  EmployeeCategory["PROFESSIONAL"] = "PROFESSIONAL";
  EmployeeCategory["TECHNICAL"] = "TECHNICAL";
  EmployeeCategory["ADMINISTRATIVE"] = "ADMINISTRATIVE";
  EmployeeCategory["OPERATIONAL"] = "OPERATIONAL";
  EmployeeCategory["SALES"] = "SALES";
  EmployeeCategory["CUSTOMER_SERVICE"] = "CUSTOMER_SERVICE";
  EmployeeCategory["LABOR"] = "LABOR";
  EmployeeCategory["INTERN_TRAINEE"] = "INTERN_TRAINEE";
})(EmployeeCategory || (exports.EmployeeCategory = EmployeeCategory = {}));
var Ethnicity;
(function (Ethnicity) {
  Ethnicity["HISPANIC_OR_LATINO"] = "HISPANIC_OR_LATINO";
  Ethnicity["BLACK_OR_AFRICAN_AMERICAN"] = "BLACK_OR_AFRICAN_AMERICAN";
  Ethnicity["WHITE"] = "WHITE";
  Ethnicity["ASIAN"] = "ASIAN";
  Ethnicity["NATIVE_AMERICAN_OR_ALASKA_NATIVE"] = "NATIVE_AMERICAN_OR_ALASKA_NATIVE";
  Ethnicity["NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER"] = "NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER";
  Ethnicity["TWO_OR_MORE_RACES"] = "TWO_OR_MORE_RACES";
  Ethnicity["OTHER"] = "OTHER";
})(Ethnicity || (exports.Ethnicity = Ethnicity = {}));
var Disability;
(function (Disability) {
  Disability["NO_DISABILITY"] = "NO_DISABILITY";
  Disability["VISUAL_IMPAIRMENT"] = "VISUAL_IMPAIRMENT";
  Disability["HEARING_IMPAIRMENT"] = "HEARING_IMPAIRMENT";
  Disability["PHYSICAL_DISABILITY"] = "PHYSICAL_DISABILITY";
  Disability["COGNITIVE_DISABILITY"] = "COGNITIVE_DISABILITY";
  Disability["MENTAL_HEALTH_CONDITION"] = "MENTAL_HEALTH_CONDITION";
  Disability["NEURODIVERSITY"] = "NEURODIVERSITY";
  Disability["SPEECH_IMPAIRMENT"] = "SPEECH_IMPAIRMENT";
  Disability["INVISIBLE_DISABILITY"] = "INVISIBLE_DISABILITY";
})(Disability || (exports.Disability = Disability = {}));
var EducationalLevel;
(function (EducationalLevel) {
  EducationalLevel["NO_FORMAL_EDUCATION"] = "NO_FORMAL_EDUCATION";
  EducationalLevel["PRIMARY_EDUCATION"] = "PRIMARY_EDUCATION";
  EducationalLevel["SECONDARY_EDUCATION"] = "SECONDARY_EDUCATION";
  EducationalLevel["VOCATIONAL_TRAINING"] = "VOCATIONAL_TRAINING";
  EducationalLevel["ASSOCIATE_DEGREE"] = "ASSOCIATE_DEGREE";
  EducationalLevel["BACHELORS_DEGREE"] = "BACHELORS_DEGREE";
  EducationalLevel["MASTERS_DEGREE"] = "MASTERS_DEGREE";
  EducationalLevel["DOCTORAL_DEGREE"] = "DOCTORAL_DEGREE";
  EducationalLevel["PROFESSIONAL_DEGREE"] = "PROFESSIONAL_DEGREE";
})(EducationalLevel || (exports.EducationalLevel = EducationalLevel = {}));
var Nationality;
(function (Nationality) {
  Nationality["AMERICAN"] = "AMERICAN";
  Nationality["CANADIAN"] = "CANADIAN";
  Nationality["BRITISH"] = "BRITISH";
  Nationality["FRENCH"] = "FRENCH";
  Nationality["GERMAN"] = "GERMAN";
  Nationality["INDIAN"] = "INDIAN";
  Nationality["CHINESE"] = "CHINESE";
  Nationality["BRAZILIAN"] = "BRAZILIAN";
  Nationality["SOUTH_AFRICAN"] = "SOUTH_AFRICAN";
  Nationality["JAPANESE"] = "JAPANESE";
})(Nationality || (exports.Nationality = Nationality = {}));
var IndigenousStatus;
(function (IndigenousStatus) {
  IndigenousStatus["INDIGENOUS"] = "INDIGENOUS";
  IndigenousStatus["NON_INDIGENOUS"] = "NON_INDIGENOUS";
  IndigenousStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(IndigenousStatus || (exports.IndigenousStatus = IndigenousStatus = {}));
var VeteranStatus;
(function (VeteranStatus) {
  VeteranStatus["VETERAN"] = "VETERAN";
  VeteranStatus["NON_VETERAN"] = "NON_VETERAN";
  VeteranStatus["ACTIVE_DUTY"] = "ACTIVE_DUTY";
  VeteranStatus["RESERVE"] = "RESERVE";
  VeteranStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(VeteranStatus || (exports.VeteranStatus = VeteranStatus = {}));
var LGBTQIdentity;
(function (LGBTQIdentity) {
  LGBTQIdentity["IDENTIFIES_AS_LGBTQ_PLUS"] = "IDENTIFIES_AS_LGBTQ_PLUS";
  LGBTQIdentity["DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS"] = "DOES_NOT_IDENTIFY_AS_LGBTQ_PLUS";
  LGBTQIdentity["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(LGBTQIdentity || (exports.LGBTQIdentity = LGBTQIdentity = {}));
var SocioeconomicStatus;
(function (SocioeconomicStatus) {
  SocioeconomicStatus["LOW_SES"] = "LOW_SES";
  SocioeconomicStatus["MIDDLE_SES"] = "MIDDLE_SES";
  SocioeconomicStatus["HIGH_SES"] = "HIGH_SES";
  SocioeconomicStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(SocioeconomicStatus || (exports.SocioeconomicStatus = SocioeconomicStatus = {}));
var Religion;
(function (Religion) {
  Religion["CHRISTIANITY"] = "CHRISTIANITY";
  Religion["ISLAM"] = "ISLAM";
  Religion["HINDUISM"] = "HINDUISM";
  Religion["BUDDHISM"] = "BUDDHISM";
  Religion["JUDAISM"] = "JUDAISM";
  Religion["SIKHISM"] = "SIKHISM";
  Religion["ATHEISM"] = "ATHEISM";
  Religion["AGNOSTICISM"] = "AGNOSTICISM";
  Religion["OTHER"] = "OTHER";
  Religion["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(Religion || (exports.Religion = Religion = {}));
var MaritalStatus;
(function (MaritalStatus) {
  MaritalStatus["SINGLE"] = "SINGLE";
  MaritalStatus["MARRIED"] = "MARRIED";
  MaritalStatus["DIVORCED"] = "DIVORCED";
  MaritalStatus["WIDOWED"] = "WIDOWED";
  MaritalStatus["SEPARATED"] = "SEPARATED";
  MaritalStatus["IN_A_PARTNERSHIP"] = "IN_A_PARTNERSHIP";
  MaritalStatus["PREFER_NOT_TO_SAY"] = "PREFER_NOT_TO_SAY";
})(MaritalStatus || (exports.MaritalStatus = MaritalStatus = {}));
var EnergyOperation;
(function (EnergyOperation) {
  EnergyOperation["CONSUMED"] = "CONSUMED";
  EnergyOperation["SOLD"] = "SOLD";
})(EnergyOperation || (exports.EnergyOperation = EnergyOperation = {}));
var EnergyType;
(function (EnergyType) {
  EnergyType["ELECTRICITY"] = "ELECTRICITY";
  EnergyType["HEATING"] = "HEATING";
  EnergyType["COOLING"] = "COOLING";
  EnergyType["STEAM"] = "STEAM";
})(EnergyType || (exports.EnergyType = EnergyType = {}));
var EnergyActivity;
(function (EnergyActivity) {
  EnergyActivity["UPSTREAM"] = "UPSTREAM";
  EnergyActivity["DOWNSTREAM"] = "DOWNSTREAM";
})(EnergyActivity || (exports.EnergyActivity = EnergyActivity = {}));
var EnergyUpstream;
(function (EnergyUpstream) {
  EnergyUpstream["PURCHASED_GOODS_AND_SERVICES"] = "PURCHASED_GOODS_AND_SERVICES";
  EnergyUpstream["CAPITAL_GOODS"] = "CAPITAL_GOODS";
  EnergyUpstream["FUEL_AND_ENERGY_RELATED_ACTIVITIES"] = "FUEL_AND_ENERGY_RELATED_ACTIVITIES";
  EnergyUpstream["UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "UPSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyUpstream["WASTE_GENERATED_IN_OPERATIONS"] = "WASTE_GENERATED_IN_OPERATIONS";
  EnergyUpstream["BUSINESS_TRAVEL"] = "BUSINESS_TRAVEL";
  EnergyUpstream["EMPLOYEE_COMMUTING"] = "EMPLOYEE_COMMUTING";
  EnergyUpstream["UPSTREAM_LEASED_ASSETS"] = "UPSTREAM_LEASED_ASSETS";
})(EnergyUpstream || (exports.EnergyUpstream = EnergyUpstream = {}));
var EnergyDownstream;
(function (EnergyDownstream) {
  EnergyDownstream["DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION"] = "DOWNSTREAM_TRANSPORTATION_AND_DISTRIBUTION";
  EnergyDownstream["PROCESSING_OF_SOLD_PRODUCTS"] = "PROCESSING_OF_SOLD_PRODUCTS";
  EnergyDownstream["USE_OF_SOLD_PRODUCTS"] = "USE_OF_SOLD_PRODUCTS";
  EnergyDownstream["END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS"] = "END_OF_LIFE_TREATMENT_OF_SOLD_PRODUCTS";
  EnergyDownstream["DOWNSTREAM_LEASED_ASSETS"] = "DOWNSTREAM_LEASED_ASSETS";
  EnergyDownstream["FRANCHISES"] = "FRANCHISES";
  EnergyDownstream["INVESTMENTS"] = "INVESTMENTS";
})(EnergyDownstream || (exports.EnergyDownstream = EnergyDownstream = {}));
exports.EnergyActivityCategories = {
  ...EnergyUpstream,
  ...EnergyDownstream
};
var BusinessMetric;
(function (BusinessMetric) {
  BusinessMetric["PRODUCTION_VOLUME"] = "PRODUCTION_VOLUME";
  BusinessMetric["SIZE"] = "SIZE";
  BusinessMetric["NUMBER_OF_FULL_TIME_EMPLOYEES"] = "NUMBER_OF_FULL_TIME_EMPLOYEES";
  BusinessMetric["MONETARY_UNITS"] = "MONETARY_UNITS";
  BusinessMetric["SERVICES_PROVIDED"] = "SERVICES_PROVIDED";
  BusinessMetric["DISTANCE_TRAVELED"] = "DISTANCE_TRAVELED";
  BusinessMetric["OPERATIONAL_HOURS"] = "OPERATIONAL_HOURS";
  BusinessMetric["ENERGY_CONSUMPTION_PER_FLOOR_AREA"] = "ENERGY_CONSUMPTION_PER_FLOOR_AREA";
  BusinessMetric["ENERGY_CONSUMPTION_PER_EMPLOYEE"] = "ENERGY_CONSUMPTION_PER_EMPLOYEE";
  BusinessMetric["ENERGY_CONSUMPTION_PER_PRODUCT"] = "ENERGY_CONSUMPTION_PER_PRODUCT";
  BusinessMetric["ENERGY_CONSUMPTION_PER_REVENUE"] = "ENERGY_CONSUMPTION_PER_REVENUE";
})(BusinessMetric || (exports.BusinessMetric = BusinessMetric = {}));
var EnergyBasisCalculation;
(function (EnergyBasisCalculation) {
  EnergyBasisCalculation["BASE_YEAR"] = "BASE_YEAR";
  EnergyBasisCalculation["BASELINE"] = "BASELINE";
})(EnergyBasisCalculation || (exports.EnergyBasisCalculation = EnergyBasisCalculation = {}));
var EnergyReductionSource;
(function (EnergyReductionSource) {
  EnergyReductionSource["ESTIMATED"] = "ESTIMATED";
  EnergyReductionSource["MODELED"] = "MODELED";
  EnergyReductionSource["DIRECT_MEASUREMENT"] = "DIRECT_MEASUREMENT";
})(EnergyReductionSource || (exports.EnergyReductionSource = EnergyReductionSource = {}));
exports.EnergyTypeReduction = {
  ALL: 'ALL',
  ...EnergyType
};
