"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SenderType = void 0;
var SenderType;
(function (SenderType) {
  SenderType["SYSTEM"] = "SYSTEM";
  SenderType["USER"] = "USER";
})(SenderType || (exports.SenderType = SenderType = {}));
