"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerType = void 0;
var PartnerType;
(function (PartnerType) {
  PartnerType["SUPPLIER"] = "SUPPLIER";
  PartnerType["VENDOR"] = "VENDOR";
  PartnerType["DISTRIBUTOR"] = "DISTRIBUTOR";
  PartnerType["WASTE_MANAGEMENT_PARTNER"] = "WASTE_MANAGEMENT_PARTNER";
})(PartnerType || (exports.PartnerType = PartnerType = {}));
