"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getEnergyTypeOptions = exports.getMaritalStatusOptions = exports.getReligionOptions = exports.getSocioeconomicStatusOptions = exports.getLGBTQIdentityOptions = exports.getVeteranStatusOptions = exports.getIndigenousStatusOptions = exports.getNationalityOptions = exports.getEducationalLevelOptions = exports.getDisabilityOptions = exports.getEthnicityOptions = exports.getDiversityIndicatorOptions = exports.getEmployeeCategoriesOptions = exports.getAgeGroupOptions = exports.getGovernanceBodyCategoryOptions = exports.purchasedElectricityUnitOption = exports.purchasedEleCalculationApproachOptions = exports.refrigerantOption = exports.mobileCombustionVehicleUnitOption = exports.activityTypeMobileOption = exports.getSiteLocation = exports.getRecoveryOperation = exports.getDisposalOperation = exports.getWasteTypeOptions = exports.getWasteSourceOptions = exports.getWasteCompositionOptions = exports.getWasteMaterialOptions = exports.getPartnerScopeOptions = exports.getPartnerStatusOptions = exports.getPartnerTypeOptions = exports.getPartnerClassificationOptions = exports.getPartnerCategoryOptions = exports.getEmployeeFunctionOptions = exports.getEmployeeLevelOptions = exports.getReportingPeriodOptionsList = exports.getContractTypes = exports.getUserScopes = exports.getUserStatuses = exports.getIllHealthTypeOptions = exports.getInjurySeverityOptions = exports.getInjuryOptions = exports.getGenderOptions = exports.getWorkerTypeOptions = exports.getAuditTypeOptions = exports.getWorkerCategoryOptions = exports.getStakeholderTypeOptions = exports.getRegionOptions = exports.getStakeholderCategoryOptions = exports.booleanOptions = exports.createOptions = void 0;
exports.getEnergyEnergyFuelTypeOptions = exports.getEnergyActivityCategoriesOptions = exports.getEnergyActivityOptions = exports.getEnergyOperationOptions = exports.getEnergyReductionSourceOptions = exports.getBasisForCalculationOptions = exports.getBusinessMetricOptions = exports.getAllEnergyTypeOptions = void 0;
const type_1 = require("../type");
const period_1 = require("../enum/period");
const stakeholder_category_1 = require("../enum/stakeholder-category");
const enum_1 = require("../enum");
const stakeholder_type_1 = require("../enum/stakeholder-type");
const activity_1 = require("../enum/activity");
const partner_1 = require("../type/partner");
const emissions_1 = require("../type/emissions");
const createOptions = (enumTypes, translationPath) => {
  return Object.keys(enumTypes).map(item => ({
    key: item,
    label: `${translationPath}.${item}`
  }));
};
exports.createOptions = createOptions;
exports.booleanOptions = [{
  key: 'yes',
  label: 'YES'
}, {
  key: 'no',
  label: 'NO'
}];
const getStakeholderCategoryOptions = () => (0, exports.createOptions)(stakeholder_category_1.StakeholderCategoryEnum, 'TOPIC.STAKE_HOLDER_CATEGORIES');
exports.getStakeholderCategoryOptions = getStakeholderCategoryOptions;
const getRegionOptions = () => (0, exports.createOptions)(enum_1.RegionEnum, 'TOPIC.REGION');
exports.getRegionOptions = getRegionOptions;
const getStakeholderTypeOptions = () => (0, exports.createOptions)(stakeholder_type_1.StakeholderTypeEnum, 'TOPIC.STAKE_HOLDER_TYPES');
exports.getStakeholderTypeOptions = getStakeholderTypeOptions;
const getWorkerCategoryOptions = () => (0, exports.createOptions)(activity_1.WorkerCategories, 'TOPIC.WORKER_CATEGORIES');
exports.getWorkerCategoryOptions = getWorkerCategoryOptions;
const getAuditTypeOptions = () => (0, exports.createOptions)(activity_1.AuditTypes, 'TOPIC.AUDIT_TYPES');
exports.getAuditTypeOptions = getAuditTypeOptions;
const getWorkerTypeOptions = () => (0, exports.createOptions)(activity_1.WorkerTypes, 'TOPIC.WORKER_TYPES');
exports.getWorkerTypeOptions = getWorkerTypeOptions;
const getGenderOptions = () => (0, exports.createOptions)(activity_1.Genders, 'TOPIC.GENDERS');
exports.getGenderOptions = getGenderOptions;
const getInjuryOptions = () => (0, exports.createOptions)(activity_1.InjuryTypes, 'TOPIC.INJURY_TYPES');
exports.getInjuryOptions = getInjuryOptions;
const getInjurySeverityOptions = () => (0, exports.createOptions)(activity_1.InjurySeverities, 'TOPIC.INJURY_SEVERITY');
exports.getInjurySeverityOptions = getInjurySeverityOptions;
const getIllHealthTypeOptions = () => (0, exports.createOptions)(activity_1.IllHealthTypes, 'TOPIC.ILL_HEALTH_TYPES');
exports.getIllHealthTypeOptions = getIllHealthTypeOptions;
const getUserStatuses = () => (0, exports.createOptions)(type_1.UserStatus, 'USER.USER_STATUS');
exports.getUserStatuses = getUserStatuses;
const getUserScopes = () => (0, exports.createOptions)(type_1.UserScope, 'USER.USER_SCOPES');
exports.getUserScopes = getUserScopes;
const getContractTypes = () => (0, exports.createOptions)(type_1.ContractType, 'USER.CONTRACT_TYPES');
exports.getContractTypes = getContractTypes;
const getReportingPeriodOptionsList = () => (0, exports.createOptions)(period_1.ReportingPeriod, 'COLLECT.REPORTING_PERIODS');
exports.getReportingPeriodOptionsList = getReportingPeriodOptionsList;
const getEmployeeLevelOptions = () => (0, exports.createOptions)(activity_1.EmployeeLevel, 'TOPIC.EMPLOYEE_LEVEL');
exports.getEmployeeLevelOptions = getEmployeeLevelOptions;
const getEmployeeFunctionOptions = () => (0, exports.createOptions)(activity_1.EmployeeFunction, 'TOPIC.EMPLOYEE_FUNCTION');
exports.getEmployeeFunctionOptions = getEmployeeFunctionOptions;
const getPartnerCategoryOptions = () => (0, exports.createOptions)(partner_1.PartnerCategory, 'PARTNER.PARTNER_CATEGORY');
exports.getPartnerCategoryOptions = getPartnerCategoryOptions;
const getPartnerClassificationOptions = () => (0, exports.createOptions)(partner_1.Classification, 'PARTNER.PARTNER_CLASSIFICATION');
exports.getPartnerClassificationOptions = getPartnerClassificationOptions;
const getPartnerTypeOptions = () => (0, exports.createOptions)(partner_1.PartnerType, 'PARTNER.PARTNER_TYPE');
exports.getPartnerTypeOptions = getPartnerTypeOptions;
const getPartnerStatusOptions = () => (0, exports.createOptions)(partner_1.PartnerStatus, 'PARTNER.STATUS_OPTIONS');
exports.getPartnerStatusOptions = getPartnerStatusOptions;
const getPartnerScopeOptions = () => (0, exports.createOptions)(partner_1.PartnerScope, 'PARTNER.PARTNER_SCOPE');
exports.getPartnerScopeOptions = getPartnerScopeOptions;
const getWasteMaterialOptions = () => (0, exports.createOptions)(activity_1.WasteMaterial, 'TOPIC.SOLID_WASTE_TOPIC.WASTE_MATERIAL');
exports.getWasteMaterialOptions = getWasteMaterialOptions;
const getWasteCompositionOptions = () => (0, exports.createOptions)(activity_1.WasteComposition, 'TOPIC.SOLID_WASTE_TOPIC.WASTE_COMPOSITION');
exports.getWasteCompositionOptions = getWasteCompositionOptions;
const getWasteSourceOptions = () => (0, exports.createOptions)(activity_1.WasteSource, 'TOPIC.SOLID_WASTE_TOPIC.WASTE_SOURCE');
exports.getWasteSourceOptions = getWasteSourceOptions;
const getWasteTypeOptions = () => (0, exports.createOptions)(activity_1.WasteType, 'TOPIC.SOLID_WASTE_TOPIC.WASTE_TYPE');
exports.getWasteTypeOptions = getWasteTypeOptions;
const getDisposalOperation = () => (0, exports.createOptions)(activity_1.DisposalOperation, 'TOPIC.SOLID_WASTE_TOPIC.DISPOSAL_OPERATION');
exports.getDisposalOperation = getDisposalOperation;
const getRecoveryOperation = () => (0, exports.createOptions)(activity_1.RecoveryOperation, 'TOPIC.SOLID_WASTE_TOPIC.RECOVERY_OPERATION');
exports.getRecoveryOperation = getRecoveryOperation;
const getSiteLocation = () => (0, exports.createOptions)(activity_1.SiteLocation, 'TOPIC.SOLID_WASTE_TOPIC.SITE_LOCATION');
exports.getSiteLocation = getSiteLocation;
const activityTypeMobileOption = () => (0, exports.createOptions)(type_1.ActivityTypeMobile, 'TOPIC.MOBILE_COMBUSTION.ACTIVITY_TYPES');
exports.activityTypeMobileOption = activityTypeMobileOption;
const mobileCombustionVehicleUnitOption = () => (0, exports.createOptions)(type_1.VehicleUnit, 'TOPIC.MOBILE_COMBUSTION.EFFICIENCY_UNIT');
exports.mobileCombustionVehicleUnitOption = mobileCombustionVehicleUnitOption;
const refrigerantOption = () => (0, exports.createOptions)(type_1.EmissionTypeRefrigerant, 'TOPIC.REFRIGERANT.EMISSION_TYPES');
exports.refrigerantOption = refrigerantOption;
const purchasedEleCalculationApproachOptions = () => (0, exports.createOptions)(type_1.PurchasedEleCalculation, 'TOPIC.PURCHASED_ELECTRICITY.CALCULATION_APPROACHES');
exports.purchasedEleCalculationApproachOptions = purchasedEleCalculationApproachOptions;
const purchasedElectricityUnitOption = () => (0, exports.createOptions)(type_1.ElectricPowerUnit, 'UNITS');
exports.purchasedElectricityUnitOption = purchasedElectricityUnitOption;
const getGovernanceBodyCategoryOptions = () => (0, exports.createOptions)(activity_1.GovernanceBodyCategories, 'TOPIC.GOVERNANCE_BODY_CATEGORIES');
exports.getGovernanceBodyCategoryOptions = getGovernanceBodyCategoryOptions;
const getAgeGroupOptions = () => (0, exports.createOptions)(activity_1.AgeGroups, 'TOPIC.AGE_GROUPS');
exports.getAgeGroupOptions = getAgeGroupOptions;
const getEmployeeCategoriesOptions = () => (0, exports.createOptions)(activity_1.EmployeeCategory, 'TOPIC.EMPLOYEE_CATEGORIES');
exports.getEmployeeCategoriesOptions = getEmployeeCategoriesOptions;
const getDiversityIndicatorOptions = () => (0, exports.createOptions)(activity_1.DiversityIndicators, 'TOPIC.DIVERSITY_INDICATORS');
exports.getDiversityIndicatorOptions = getDiversityIndicatorOptions;
const getEthnicityOptions = () => (0, exports.createOptions)(activity_1.Ethnicity, 'TOPIC.ETHNICITY');
exports.getEthnicityOptions = getEthnicityOptions;
const getDisabilityOptions = () => (0, exports.createOptions)(activity_1.Disability, 'TOPIC.DISABILITY');
exports.getDisabilityOptions = getDisabilityOptions;
const getEducationalLevelOptions = () => (0, exports.createOptions)(activity_1.EducationalLevel, 'TOPIC.EDUCATIONAL_LEVEL');
exports.getEducationalLevelOptions = getEducationalLevelOptions;
const getNationalityOptions = () => (0, exports.createOptions)(activity_1.Nationality, 'TOPIC.NATIONALITY');
exports.getNationalityOptions = getNationalityOptions;
const getIndigenousStatusOptions = () => (0, exports.createOptions)(activity_1.IndigenousStatus, 'TOPIC.INDIGENOUS_STATUS');
exports.getIndigenousStatusOptions = getIndigenousStatusOptions;
const getVeteranStatusOptions = () => (0, exports.createOptions)(activity_1.VeteranStatus, 'TOPIC.VETERAN_STATUS');
exports.getVeteranStatusOptions = getVeteranStatusOptions;
const getLGBTQIdentityOptions = () => (0, exports.createOptions)(activity_1.LGBTQIdentity, 'TOPIC.LGBTQ_IDENTITY');
exports.getLGBTQIdentityOptions = getLGBTQIdentityOptions;
const getSocioeconomicStatusOptions = () => (0, exports.createOptions)(activity_1.SocioeconomicStatus, 'TOPIC.SOCIOECONOMIC_STATUS');
exports.getSocioeconomicStatusOptions = getSocioeconomicStatusOptions;
const getReligionOptions = () => (0, exports.createOptions)(activity_1.Religion, 'TOPIC.RELIGION');
exports.getReligionOptions = getReligionOptions;
const getMaritalStatusOptions = () => (0, exports.createOptions)(activity_1.MaritalStatus, 'TOPIC.MARITAL_STATUS');
exports.getMaritalStatusOptions = getMaritalStatusOptions;
const getEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyType, 'TOPIC.ENERGY_TYPES');
exports.getEnergyTypeOptions = getEnergyTypeOptions;
const getAllEnergyTypeOptions = () => (0, exports.createOptions)(activity_1.EnergyTypeReduction, 'TOPIC.ENERGY_TYPES');
exports.getAllEnergyTypeOptions = getAllEnergyTypeOptions;
const getBusinessMetricOptions = () => (0, exports.createOptions)(activity_1.BusinessMetric, 'TOPIC.BUSINESS_METRICS');
exports.getBusinessMetricOptions = getBusinessMetricOptions;
const getBasisForCalculationOptions = () => (0, exports.createOptions)(activity_1.EnergyBasisCalculation, 'TOPIC.CALCULATION_BASIS');
exports.getBasisForCalculationOptions = getBasisForCalculationOptions;
const getEnergyReductionSourceOptions = () => (0, exports.createOptions)(activity_1.EnergyReductionSource, 'TOPIC.REDUCTION_SOURCES');
exports.getEnergyReductionSourceOptions = getEnergyReductionSourceOptions;
const getEnergyOperationOptions = () => (0, exports.createOptions)(activity_1.EnergyOperation, 'TOPIC.OPERATIONS');
exports.getEnergyOperationOptions = getEnergyOperationOptions;
const getEnergyActivityOptions = () => (0, exports.createOptions)(activity_1.EnergyActivity, 'TOPIC.ACTIVITY_TYPES');
exports.getEnergyActivityOptions = getEnergyActivityOptions;
const getEnergyActivityCategoriesOptions = () => (0, exports.createOptions)(activity_1.EnergyActivityCategories, 'TOPIC.ACTIVITY_CATEGORIES');
exports.getEnergyActivityCategoriesOptions = getEnergyActivityCategoriesOptions;
const getEnergyEnergyFuelTypeOptions = () => (0, exports.createOptions)(emissions_1.EnergyFuelType, 'ENERGY_EMISSION_FACTOR.FUEL_TYPE_OPTIONS');
exports.getEnergyEnergyFuelTypeOptions = getEnergyEnergyFuelTypeOptions;
