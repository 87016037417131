"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StakeholderCategoryEnum = void 0;
var StakeholderCategoryEnum;
(function (StakeholderCategoryEnum) {
  StakeholderCategoryEnum["GOVERNANCE_BODY_MEMBERS"] = "GOVERNANCE_BODY_MEMBERS";
  StakeholderCategoryEnum["EMPLOYEES"] = "EMPLOYEES";
  StakeholderCategoryEnum["BUSINESS_PARTNERS"] = "BUSINESS_PARTNERS";
})(StakeholderCategoryEnum || (exports.StakeholderCategoryEnum = StakeholderCategoryEnum = {}));
