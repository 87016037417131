"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerStatus = void 0;
var PartnerStatus;
(function (PartnerStatus) {
  PartnerStatus["ACTIVE"] = "ACTIVE";
  PartnerStatus["INACTIVE"] = "INACTIVE";
})(PartnerStatus || (exports.PartnerStatus = PartnerStatus = {}));
