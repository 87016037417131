import { Injectable } from '@angular/core';
import { FormService } from '@ieCore/services/form.service';
import { RegionListInterface } from '@ieShared/interfaces/region.interface';
import { ReplaySubject } from 'rxjs';
import { EnumLanguage } from '@ieCore/enums/language';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  currentLanguage: string = EnumLanguage.EN;
  regionMapping?: RegionListInterface;
  $regionMapping = new ReplaySubject<RegionListInterface>(1);

  constructor(private formService: FormService) {}

  // This will be used after multilanguage implementation
  changeCurrentLanguage(lang: string) {
    this.currentLanguage = lang;
    this.initRegions();
  }

  geNameByCode(code: string) {
    return this.regionMapping![code];
  }

  initRegions() {
    this.formService
      .getRegions(this.currentLanguage)
      .response.subscribe((regions) => {
        this.regionMapping = {};
        regions.forEach((region) => {
          this.regionMapping![region.code] = region.name;
        });

        this.$regionMapping.next(this.regionMapping!);
      });
  }
}
