"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RelevancyType = void 0;
var RelevancyType;
(function (RelevancyType) {
  RelevancyType["ENTERPRISE"] = "ENTERPRISE";
  RelevancyType["PARTNER"] = "PARTNER";
})(RelevancyType || (exports.RelevancyType = RelevancyType = {}));
