"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerRelationStatus = void 0;
var PartnerRelationStatus;
(function (PartnerRelationStatus) {
  PartnerRelationStatus["ACTIVE"] = "ACTIVE";
  PartnerRelationStatus["INACTIVE"] = "INACTIVE";
})(PartnerRelationStatus || (exports.PartnerRelationStatus = PartnerRelationStatus = {}));
