"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FacilityStatus = void 0;
var FacilityStatus;
(function (FacilityStatus) {
  FacilityStatus["ACTIVE"] = "ACTIVE";
  FacilityStatus["INACTIVE"] = "INACTIVE";
})(FacilityStatus || (exports.FacilityStatus = FacilityStatus = {}));
