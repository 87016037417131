"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PurchasedEleCalculation = exports.EmissionTypeRefrigerant = exports.ActivityTypeMobile = void 0;
var ActivityTypeMobile;
(function (ActivityTypeMobile) {
  ActivityTypeMobile["DISTANCE_ACTIVITY"] = "DISTANCE_ACTIVITY";
  ActivityTypeMobile["FUEL_USE"] = "FUEL_USE";
})(ActivityTypeMobile || (exports.ActivityTypeMobile = ActivityTypeMobile = {}));
var EmissionTypeRefrigerant;
(function (EmissionTypeRefrigerant) {
  EmissionTypeRefrigerant["ASSEMBLY_EMISSIONS"] = "ASSEMBLY_EMISSIONS";
  EmissionTypeRefrigerant["OPERATION_EMISSIONS"] = "OPERATION_EMISSIONS";
  EmissionTypeRefrigerant["DISPOSAL_EMISSIONS"] = "DISPOSAL_EMISSIONS";
})(EmissionTypeRefrigerant || (exports.EmissionTypeRefrigerant = EmissionTypeRefrigerant = {}));
var PurchasedEleCalculation;
(function (PurchasedEleCalculation) {
  PurchasedEleCalculation["LOCATION_BASED"] = "LOCATION_BASED";
  PurchasedEleCalculation["MARKET_BASED"] = "MARKET_BASED";
})(PurchasedEleCalculation || (exports.PurchasedEleCalculation = PurchasedEleCalculation = {}));
