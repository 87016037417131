"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerTabs = exports.TaskTabs = void 0;
var TaskTabs;
(function (TaskTabs) {
  TaskTabs["FACILITY_DATA"] = "FACILITY_DATA";
  TaskTabs["PARTNER_DATA"] = "PARTNER_DATA";
  TaskTabs["PORTFOLIO_DATA"] = "PORTFOLIO_DATA";
})(TaskTabs || (exports.TaskTabs = TaskTabs = {}));
var PartnerTabs;
(function (PartnerTabs) {
  PartnerTabs["ALL"] = "ALL";
  PartnerTabs["PARTNER"] = "PARTNER";
  PartnerTabs["SELF"] = "SELF";
})(PartnerTabs || (exports.PartnerTabs = PartnerTabs = {}));
