"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrgType = void 0;
var OrgType;
(function (OrgType) {
  OrgType["ENTERPRISE"] = "ENTERPRISE";
  OrgType["PARTNER"] = "PARTNER";
  OrgType["PORTFOLIO"] = "PORTFOLIO";
  OrgType["AUDITOR"] = "AUDITOR";
  OrgType["CERTIFIER"] = "CERTIFIER";
})(OrgType || (exports.OrgType = OrgType = {}));
