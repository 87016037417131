"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FacilityType = void 0;
var FacilityType;
(function (FacilityType) {
  FacilityType["BRANCH"] = "BRANCH";
  FacilityType["FACTORY"] = "FACTORY";
  FacilityType["WAREHOUSE"] = "WAREHOUSE";
})(FacilityType || (exports.FacilityType = FacilityType = {}));
