"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InAppStatus = void 0;
var InAppStatus;
(function (InAppStatus) {
  InAppStatus["READ"] = "READ";
  InAppStatus["UNREAD"] = "UNREAD";
  InAppStatus["SEEN"] = "SEEN";
})(InAppStatus || (exports.InAppStatus = InAppStatus = {}));
