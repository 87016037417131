import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { TranslateService } from '@ngx-translate/core';
import { PaginationInterface } from '@ieRoot/app/shared/interfaces/pagination.interface';
import {
  requestLoading,
  RequestLoadingType,
} from '../helpers/request-loading.helper';
import { PaginatedResponseDto } from 'shared-general-libs/dto/pagination';
import {
  NotificationResponseDto,
  UpdateNotificationInAppStatusDto,
} from 'shared-general-libs/dto/notification';
import { NotificationRepository } from '../repositories/notification.repository';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private nzNotificationService: NzNotificationService,
    private translationService: TranslateService,
    private notificationRepository: NotificationRepository,
  ) {}

  success(title: string, message: string, params?: object): void {
    const trTitle = title ? this.translationService.instant(title) : null;
    const trMessage = message
      ? this.translationService.instant(message, params)
      : message;
    this.nzNotificationService.success(trTitle, trMessage);
  }

  error(title: string, message: string, params?: object): void {
    const trTitle = title ? this.translationService.instant(title) : null;
    const trMessage = message
      ? this.translationService.instant(message, params)
      : message;
    this.nzNotificationService.error(trTitle, trMessage);
  }

  warning(title: string, message: string, params?: object): void {
    const trTitle = title ? this.translationService.instant(title) : null;
    const trMessage = message
      ? this.translationService.instant(message, params)
      : message;
    this.nzNotificationService.warning(trTitle, trMessage);
  }

  info(title: string, message: string, params?: object): void {
    const trTitle = title ? this.translationService.instant(title) : null;
    const trMessage = message
      ? this.translationService.instant(message, params)
      : message;
    this.nzNotificationService.info(trTitle, trMessage);
  }

  getNotifications(
    params: PaginationInterface,
  ): RequestLoadingType<PaginatedResponseDto<NotificationResponseDto>> {
    return requestLoading(this.notificationRepository.getNotifications(params));
  }

  markAsRead(
    id: string,
    payload: UpdateNotificationInAppStatusDto,
  ): RequestLoadingType<NotificationResponseDto> {
    return requestLoading(this.notificationRepository.markAsRead(id, payload));
  }

  markAsReadAll(
    payload: UpdateNotificationInAppStatusDto,
  ): RequestLoadingType<NotificationResponseDto> {
    return requestLoading(this.notificationRepository.markAsReadAll(payload));
  }
}
