"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RegionEnum = void 0;
var RegionEnum;
(function (RegionEnum) {
  RegionEnum["NORTH_AMERICA"] = "NORTH_AMERICA";
  RegionEnum["EUROPE"] = "EUROPE";
  RegionEnum["ASIA"] = "ASIA";
  RegionEnum["SOUTH_AMERICA"] = "SOUTH_AMERICA";
  RegionEnum["AFRICA"] = "AFRICA";
  RegionEnum["AUSTRALIA"] = "AUSTRALIA";
  RegionEnum["MIDDLE_EAST"] = "MIDDLE_EAST";
})(RegionEnum || (exports.RegionEnum = RegionEnum = {}));
