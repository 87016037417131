"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SMSStatus = void 0;
var SMSStatus;
(function (SMSStatus) {
  SMSStatus["SENT"] = "SENT";
  SMSStatus["FAILED"] = "FAILED";
})(SMSStatus || (exports.SMSStatus = SMSStatus = {}));
