"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DCR_DATA_APPROVER_PERMISSIONS = exports.DCR_DATA_OWNER_PERMISSIONS = exports.PARTNER_PERMISSION = exports.PartnerRoleName = exports.PERMISSION_LIST = exports.PartnerPermissions = exports.Permission = void 0;
exports.flattenPermissions = flattenPermissions;
exports.getDcrDataOwnerPermissions = getDcrDataOwnerPermissions;
exports.getDcrDataApproverPermissions = getDcrDataApproverPermissions;
exports.Permission = {
  Organization: {
    VIEW: 'org:view',
    ADD: 'org:add',
    EDIT: 'org:edit',
    DELETE: 'org:delete',
    ADD_PARTNER: 'org:add_partner'
  },
  User: {
    VIEW: 'user:view',
    ADD: 'user:add',
    EDIT: 'user:edit',
    DELETE: 'user:delete',
    ADD_ENTERPRISE_ADMIN: 'user:add_enterprise_admin'
  },
  Role: {
    VIEW: 'role:view',
    ADD: 'role:add',
    EDIT: 'role:edit',
    DELETE: 'role:delete'
  },
  Storage: {
    VIEW: 'storage:view',
    ADD: 'storage:add',
    DELETE: 'storage:delete'
  },
  Facility: {
    VIEW: 'facility:view',
    ADD: 'facility:add',
    EDIT: 'facility:edit',
    DELETE: 'facility:delete',
    ASSIGN_USER: 'facility:assign_user'
  },
  DcrDataOwner: {
    VIEW: 'dcr_data_owner:view',
    ADD: 'dcr_data_owner:add',
    EDIT: 'dcr_data_owner:edit',
    DELETE: 'dcr_data_owner:delete',
    ADD_NOTE: 'dcr_data_owner:add_note'
  },
  DcrDataApprover: {
    APPROVE: 'dcr_data_approver:approve',
    REJECT: 'dcr_data_approver:reject'
  },
  Partner: {
    VIEW: 'partner:view',
    ADD: 'partner:add',
    EDIT: 'partner:edit',
    DELETE: 'partner:delete'
  },
  EmissionFactor: {
    VIEW: 'emission_factor:view',
    ADD: 'emission_factor:add',
    EDIT: 'emission_factor:edit',
    DELETE: 'emission_factor:delete'
  },
  Equipment: {
    VIEW: 'equipment:view',
    ADD: 'equipment:add',
    EDIT: 'equipment:edit',
    DELETE: 'equipment:delete'
  },
  Note: {
    VIEW: 'note:view',
    ADD: 'note:add',
    EDIT: 'note:edit',
    DELETE: 'note:delete'
  },
  Registry: {
    VIEW: 'Registry:view menu Registry',
    REOPEN: 'Registry:view menu Registry Reopen',
    VIEW_NOTES: 'Registry:view menu Registry View Notes',
    ADD_NOTES: 'Registry:view menu Registry Add Notes'
  },
  Collect: {
    VIEW: 'collect:view',
    CREATE_DCR: 'collect:create_dcr',
    UPDATE_DCR: 'collect:update_dcr',
    SEND_REQUEST_DCR: 'collect:send_request_dcr',
    VIEW_NOTES: 'collect:view_notes',
    ADD_NOTES: 'collect:add_notes',
    VIEW_MENU_COMPLETE: 'collect:view_menu_complete',
    DELETE_DCR: 'collect:delete_dcr',
    CREATE_IDR: 'collect:create_idr',
    UPDATE_IDR: 'collect:update_idr',
    VIEW_IDR: 'collect:view_idr',
    DELETE_IDR: 'collect:delete_idr'
  },
  Activity: {
    VIEW: 'activity:view',
    CREATE: 'activity:create'
  },
  DataContainer: {
    VIEW: 'dc:view',
    EDIT: 'dc:edit'
  },
  MyTask: {
    VIEW: 'task:view',
    CREATE: 'task:create',
    EDIT: 'task:edit',
    DELETE: 'task:delete',
    VIEW_OTHERS_TASKS: 'task:view_others_tasks'
  },
  Review: {
    SUBMIT: 'review:submit',
    VIEW: 'review:view',
    RESPOND_TO_REQUEST: 'review:respond',
    UPDATE: 'review:update',
    DELETE: 'review:delete',
    VIEW_NOTES: 'review:view_notes',
    ADD_NOTES: 'review:add_notes'
  },
  PartnerRequest: {
    SEND: 'partner_request:send',
    VIEW: 'partner_request:view',
    EDIT: 'partner_request:edit',
    DELETE: 'partner_request:delete'
  },
  Audit: {
    VIEW: 'Audit:view menu Audit',
    CREATE_REQUEST: 'Audit:view menu Audit Create Request',
    RESPOND_TO_REQUEST: 'Audit:view menu Audit Respond to Request',
    VIEW_NOTES: 'Audit:view menu Audit View Notes',
    ADD_NOTES: 'Audit:view menu Audit Add Notes'
  },
  Track: {
    VIEW: 'Track:view menu Track'
  },
  Certify: {
    VIEW: 'Certify:view menu Certify',
    CREATE_APPLICATION: 'Certify:view menu Certify Create Application',
    RESPOND_TO_REQUEST: 'Certify:view menu Certify Respond to Request',
    VIEW_NOTES: 'Certify:view menu Certify View Notes',
    ADD_NOTES: 'Certify:view menu Certify Add Notes'
  },
  Reports: {
    VIEW_MENU: 'Reports:view menu Reports menu',
    TEMPLATES: 'Reports:view menu Reports Templates',
    ADD_TEMPLATES: 'Reports:view menu Reports Add Templates',
    EDIT_TEMPLATES: 'Reports:view menu Reports Edit Templates',
    DELETE_TEMPLATES: 'Reports:view menu Reports Delete Templates',
    REPORTS: 'Reports:view menu Reports',
    ADD_REPORTS: 'Reports:view menu Add Reports',
    DELETE_REPORTS: 'Reports:view menu Delete Reports'
  },
  Analyze: {
    VIEW: 'Analyze:view menu Analyze'
  },
  Projects: {
    VIEW: 'Projects:view menu Projects',
    CREATE: 'Projects:view menu Create Projects',
    UPDATE: 'Projects:view menu Update Projects',
    SEND_REQUEST: 'Projects:view menu Send Projects request',
    VIEW_MILESTONE: 'Projects:view menu View Projects milestone',
    CREATE_MILESTONE: 'Projects:view menu Create Projects milestone',
    EDIT_MILESTONE: 'Projects:view menu Edit Projects milestone',
    DELETE_MILESTONE: 'Projects:view menu Delete Projects milestone',
    VIEW_ACTIVITY: 'Projects:view menu View Projects activity',
    CREATE_ACTIVITY: 'Projects:view menu Create Projects activity',
    EDIT_ACTIVITY: 'Projects:view menu Edit Projects activity',
    DELETE_ACTIVITY: 'Projects:view menu Delete Projects activity',
    VIEW_EXPENSES: 'Projects:view menu View Projects expenses',
    CREATE_EXPENSES: 'Projects:view menu Create Projects expenses',
    EDIT_EXPENSES: 'Projects:view menu Edit Projects expenses',
    DELETE_EXPENSES: 'Projects:view menu Delete Projects expenses',
    VIEW_TEAM: 'Projects:view menu View Projects team',
    CREATE_TEAM: 'Projects:view menu Create Projects team',
    EDIT_TEAM: 'Projects:view menu Edit Projects team',
    DELETE_TEAM: 'Projects:view menu Delete Projects team'
  },
  Manage: {
    VIEW: 'Manage:view menu Manage',
    COMPANY: 'Manage:view menu Manage Company',
    EDIT_COMPANY: 'Manage:view menu Manage Edit Company',
    FACILITY: 'Manage:view menu Manage Facility',
    ADD_FACILITY: 'Manage:view menu Manage Add Facility',
    EDIT_FACILITY: 'Manage:view menu Manage Edit Facility',
    PARTNER: 'Manage:view menu Manage Partner',
    ADD_PARTNER: 'Manage:view menu Manage Add Partner',
    EDIT_PARTNER: 'Manage:view menu Manage Edit Partner',
    PORTFOLIO: 'Manage:view menu Manage Portfolio',
    ADD_PORTFOLIO: 'Manage:view menu Manage Add Portfolio',
    EDIT_PORTFOLIO: 'Manage:view menu Manage Edit Portfolio',
    USERS: 'Manage:view menu Manage Users',
    ADD_USERS: 'Manage:view menu Manage Add Users',
    EDIT_USERS: 'Manage:view menu Manage Edit Users',
    ROLES_PRIVILEGES: 'Manage:view menu Manage Roles & Privileges',
    ADD_ROLES_PRIVILEGES: 'Manage:view menu Manage Add Roles & Privileges',
    EDIT_ROLES_PRIVILEGES: 'Manage:view menu Manage Edit Roles & Privileges',
    DELETE_ROLES_PRIVILEGES: 'Manage:view menu Manage Delete Roles & Privileges',
    TOPICS: 'Manage:view menu Manage Topics',
    ADD_TOPICS: 'Manage:view menu Manage Add Topics',
    EDIT_TOPICS: 'Manage:view menu Manage Edit Topics',
    FUEL: 'Manage:view menu Manage Fuel',
    ADD_FUEL: 'Manage:view menu Manage Add Fuel',
    EDIT_FUEL: 'Manage:view menu Manage Edit Fuel',
    DELETE_FUEL: 'Manage:view menu Manage Delete Fuel',
    TRANSPORT: 'Manage:view menu Manage Transport',
    ADD_TRANSPORT: 'Manage:view menu Manage Add Transport',
    EDIT_TRANSPORT: 'Manage:view menu Manage Edit Transport',
    DELETE_TRANSPORT: 'Manage:view menu Manage Delete Transport',
    PURCHASED_ELECTRICITY: 'Manage:view menu Manage Purchased Electricity',
    ADD_PURCHASED_ELECTRICITY: 'Manage:view menu Manage Add Purchased Electricity',
    EDIT_PURCHASED_ELECTRICITY: 'Manage:view menu Manage Edit Purchased Electricity',
    DELETE_PURCHASED_ELECTRICITY: 'Manage:view menu Manage Delete Purchased Electricity',
    STEAM_HEAT: 'Manage:view menu Manage Steam and Heat',
    ADD_STEAM_HEAT: 'Manage:view menu Manage Add Steam and Heat',
    EDIT_STEAM_HEAT: 'Manage:view menu Manage Edit Steam and Heat',
    DELETE_STEAM_HEAT: 'Manage:view menu Manage Delete Steam and Heat',
    REFRIGERANTS: 'Manage:view menu Manage Refrigerants',
    ADD_REFRIGERANTS: 'Manage:view menu Manage Add Refrigerants',
    EDIT_REFRIGERANTS: 'Manage:view menu Manage Edit Refrigerants',
    DELETE_REFRIGERANTS: 'Manage:view menu Manage Delete Refrigerants'
  },
  Goals: {
    VIEW: 'Goals:view menu Goals',
    CREATE: 'Goals:view menu Create Goals',
    UPDATE: 'Goals:view menu Update Goals',
    CREATE_SUBGOAL: 'Goals:view menu Create Subgoal',
    SEND_REQUEST: 'Goals:view menu send Request Goal',
    COMPLETE: 'Goals:view menu Goal Complete'
  },
  MaterialitySurvey: {
    VIEW: 'Materiality Survey:view menu Materiality Survey',
    CREATE: 'Materiality Survey:view menu Create Materiality Survey',
    UPDATE: 'Materiality Survey:view menu Update Materiality Survey'
  },
  WasteTracking: {
    VIEW: 'Waste Tracking:view menu Waste Tracking',
    CREATE: 'Waste Tracking:view menu Create Waste Tracking',
    UPDATE: 'Waste Tracking:view menu Update Waste Tracking'
  },
  Applications: {
    ASSIGN: 'Applications:view menu Assign Applications',
    UPDATE_ASSIGN: 'Applications:view menu Update Assign Applications',
    VERIFY: 'Applications:view menu Verify Applications',
    UPDATE_VERIFY: 'Applications:view menu Update Verify Applications',
    REVIEW: 'Applications:view menu Review Applications',
    UPDATE_REVIEW: 'Applications:view menu Update Review Applications',
    CERTIFICATIONS: 'Applications:view menu Certifications Applications',
    UPDATE_CERTIFICATIONS: 'Applications:view menu Update Certifications Applications'
  },
  Notification: {
    VIEW: 'Notfication:view menu Notification'
  }
};
exports.PartnerPermissions = {
  User: {
    VIEW: 'user:view',
    ADD: 'user:add',
    EDIT: 'user:edit',
    DELETE: 'user:delete'
  },
  Role: {
    VIEW: 'role:view',
    ADD: 'role:add',
    EDIT: 'role:edit',
    DELETE: 'role:delete'
  },
  Facility: {
    VIEW: 'facility:view',
    ASSIGN_USER: 'facility:assign_user'
  },
  DcrDataOwner: {
    VIEW: 'dcr_data_owner:view',
    ADD: 'dcr_data_owner:add',
    EDIT: 'dcr_data_owner:edit',
    DELETE: 'dcr_data_owner:delete',
    ADD_NOTE: 'dcr_data_owner:add_note'
  },
  DcrDataApprover: {
    APPROVE: 'dcr_data_approver:approve',
    REJECT: 'dcr_data_approver:reject'
  },
  Partner: {
    VIEW: 'partner:view',
    ADD: 'partner:add',
    EDIT: 'partner:edit',
    DELETE: 'partner:delete'
  },
  EmissionFactor: {
    VIEW: 'emission_factor:view'
  },
  Equipment: {
    VIEW: 'equipment:view'
  },
  PartnerRequest: {
    SEND: 'partner_request:send',
    VIEW: 'partner_request:view',
    EDIT: 'partner_request:edit',
    DELETE: 'partner_request:delete'
  },
  Activity: {
    VIEW: 'activity:view',
    CREATE: 'activity:create'
  },
  MyTask: {
    VIEW: 'task:view',
    CREATE: 'task:create',
    EDIT: 'task:edit',
    DELETE: 'task:delete',
    VIEW_OTHERS_TASKS: 'task:view_others_tasks'
  },
  Notification: {
    VIEW: 'Notfication:view menu Notification'
  },
  Note: {
    VIEW: 'note:view',
    ADD: 'note:add',
    EDIT: 'note:edit',
    DELETE: 'note:delete'
  },
  Collect: {
    VIEW: 'collect:view',
    CREATE_DCR: 'collect:create_dcr',
    UPDATE_DCR: 'collect:update_dcr',
    SEND_REQUEST_DCR: 'collect:send_request_dcr',
    VIEW_NOTES: 'collect:view_notes',
    ADD_NOTES: 'collect:add_notes',
    VIEW_MENU_COMPLETE: 'collect:view_menu_complete',
    DELETE_DCR: 'collect:delete_dcr',
    CREATE_IDR: 'collect:create_idr',
    UPDATE_IDR: 'collect:update_idr',
    VIEW_IDR: 'collect:view_idr',
    DELETE_IDR: 'collect:delete_idr'
  },
  Manage: {
    VIEW_PARTNER: exports.Permission.Manage.PARTNER,
    ADD_PARTNER: exports.Permission.Manage.ADD_PARTNER,
    EDIT_PARTNER: exports.Permission.Manage.EDIT_PARTNER,
    ADD_USER: exports.Permission.Manage.ADD_USERS,
    EDIT_USER: exports.Permission.Manage.EDIT_USERS,
    VIEW_USER: exports.Permission.Manage.USERS,
    VIEW_ROLE_PERMISSION: exports.Permission.Manage.ROLES_PRIVILEGES,
    ADD_ROLE_PERMISSION: exports.Permission.Manage.ADD_ROLES_PRIVILEGES,
    EDIT_ROLE_PERMISSION: exports.Permission.Manage.EDIT_ROLES_PRIVILEGES,
    DELETE_ROLE_PERMISSION: exports.Permission.Manage.DELETE_ROLES_PRIVILEGES
  }
};
function flattenPermissions(obj, prefix = '') {
  return Object.keys(obj).reduce((res, el) => {
    const key = prefix ? `${prefix}:${el}` : el;
    if (typeof obj[el] === 'object') {
      res.push(...flattenPermissions(obj[el], key));
    } else {
      res.push(obj[el]);
    }
    return res;
  }, []);
}
exports.PERMISSION_LIST = flattenPermissions(exports.Permission);
exports.PartnerRoleName = 'partner_admin';
exports.PARTNER_PERMISSION = flattenPermissions(exports.PartnerPermissions);
function getDcrDataOwnerPermissions() {
  return flattenPermissions(exports.Permission.DcrDataOwner);
}
function getDcrDataApproverPermissions() {
  return flattenPermissions(exports.Permission.DcrDataApprover);
}
exports.DCR_DATA_OWNER_PERMISSIONS = getDcrDataOwnerPermissions();
exports.DCR_DATA_APPROVER_PERMISSIONS = getDcrDataApproverPermissions();
