"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StakeholderTypeEnum = void 0;
var StakeholderTypeEnum;
(function (StakeholderTypeEnum) {
  StakeholderTypeEnum["GOVERNANCE_BODY_MEMBERS"] = "GOVERNANCE_BODY_MEMBERS";
  StakeholderTypeEnum["BOARD_OF_DIRECTORS"] = "BOARD_OF_DIRECTORS";
  StakeholderTypeEnum["MANAGEMENT_COMMITTEE"] = "MANAGEMENT_COMMITTEE";
})(StakeholderTypeEnum || (exports.StakeholderTypeEnum = StakeholderTypeEnum = {}));
