"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EntityType = void 0;
var EntityType;
(function (EntityType) {
  EntityType["ACTIVITY"] = "ACTIVITY";
})(EntityType || (exports.EntityType = EntityType = {}));
