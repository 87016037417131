"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatusReviewDR = exports.StatusReview = exports.StatusApplication = exports.StatusActivity = exports.StatusDr = exports.StatusPartnerRequest = exports.StatusIdr = exports.StatusDcr = void 0;
var StatusDcr;
(function (StatusDcr) {
  StatusDcr["NEW"] = "NEW";
  StatusDcr["IN_PROGRESS"] = "IN_PROGRESS";
  StatusDcr["COMPLETED"] = "COMPLETED";
})(StatusDcr || (exports.StatusDcr = StatusDcr = {}));
var StatusIdr;
(function (StatusIdr) {
  StatusIdr["NEW"] = "NEW";
  StatusIdr["IN_PROGRESS"] = "IN_PROGRESS";
  StatusIdr["PENDING_REVIEW"] = "PENDING_REVIEW";
  StatusIdr["IN_REVIEW"] = "IN_REVIEW";
  StatusIdr["REVIEW_COMPLETED"] = "REVIEW_COMPLETED";
  StatusIdr["APPROVED"] = "APPROVED";
  StatusIdr["REJECTED"] = "REJECTED";
})(StatusIdr || (exports.StatusIdr = StatusIdr = {}));
var StatusPartnerRequest;
(function (StatusPartnerRequest) {
  StatusPartnerRequest["NOT_STARTED"] = "NOT_STARTED";
  StatusPartnerRequest["NOT_SENT"] = "NOT_SENT";
  StatusPartnerRequest["REQUEST_SENT"] = "REQUEST_SENT";
  StatusPartnerRequest["IN_PROGRESS"] = "IN_PROGRESS";
  StatusPartnerRequest["PENDING"] = "PENDING";
  StatusPartnerRequest["COMPLETED"] = "COMPLETED";
})(StatusPartnerRequest || (exports.StatusPartnerRequest = StatusPartnerRequest = {}));
var StatusDr;
(function (StatusDr) {
  StatusDr["NEW"] = "NEW";
  StatusDr["COMPLETED"] = "COMPLETED";
  StatusDr["IN_PROGRESS"] = "IN_PROGRESS";
  StatusDr["PENDING_REVIEW"] = "PENDING_REVIEW";
  StatusDr["APPROVED"] = "APPROVED";
  StatusDr["REJECTED"] = "REJECTED";
  StatusDr["AUDITED"] = "AUDITED";
})(StatusDr || (exports.StatusDr = StatusDr = {}));
var StatusActivity;
(function (StatusActivity) {
  StatusActivity["NEW"] = "NEW";
  StatusActivity["APPROVED"] = "APPROVED";
  StatusActivity["REJECTED"] = "REJECTED";
  StatusActivity["VERIFIED"] = "VERIFIED";
})(StatusActivity || (exports.StatusActivity = StatusActivity = {}));
var StatusApplication;
(function (StatusApplication) {
  StatusApplication["NEW"] = "NEW";
  StatusApplication["SUBMITTED"] = "SUBMITTED";
  StatusApplication["VERIFIED"] = "VERIFIED";
  StatusApplication["APPROVED"] = "APPROVED";
  StatusApplication["REJECTED"] = "REJECTED";
  StatusApplication["COMPLETED"] = "COMPLETED";
  StatusApplication["IN_PROGRESS"] = "IN_PROGRESS";
  StatusApplication["CERTIFICATE_ISSUED"] = "CERTIFICATE_ISSUED";
  StatusApplication["PENDING"] = "PENDING";
})(StatusApplication || (exports.StatusApplication = StatusApplication = {}));
var StatusReview;
(function (StatusReview) {
  StatusReview["NEW"] = "NEW";
  StatusReview["IN_REVIEW"] = "IN_REVIEW";
  StatusReview["REVIEW_COMPLETED"] = "REVIEW_COMPLETED";
  StatusReview["APPROVED"] = "APPROVED";
  StatusReview["REJECTED"] = "REJECTED";
})(StatusReview || (exports.StatusReview = StatusReview = {}));
var StatusReviewDR;
(function (StatusReviewDR) {
  StatusReviewDR["APPROVED"] = "APPROVED";
  StatusReviewDR["REJECTED"] = "REJECTED";
})(StatusReviewDR || (exports.StatusReviewDR = StatusReviewDR = {}));
