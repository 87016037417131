"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnergyFuelType = void 0;
var EnergyFuelType;
(function (EnergyFuelType) {
  EnergyFuelType["NONRENEWABLE"] = "NONRENEWABLE";
  EnergyFuelType["RENEWABLE"] = "RENEWABLE";
})(EnergyFuelType || (exports.EnergyFuelType = EnergyFuelType = {}));
