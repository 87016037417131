"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StorageProvider = void 0;
var StorageProvider;
(function (StorageProvider) {
  StorageProvider["GCP"] = "GCP";
})(StorageProvider || (exports.StorageProvider = StorageProvider = {}));
