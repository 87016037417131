"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OrgStatus = void 0;
var OrgStatus;
(function (OrgStatus) {
  OrgStatus["ACTIVE"] = "ACTIVE";
  OrgStatus["INACTIVE"] = "INACTIVE";
})(OrgStatus || (exports.OrgStatus = OrgStatus = {}));
