"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AvailableService = void 0;
var AvailableService;
(function (AvailableService) {
  AvailableService["INAPP"] = "INAPP";
  AvailableService["EMAIL"] = "EMAIL";
  AvailableService["SMS"] = "SMS";
})(AvailableService || (exports.AvailableService = AvailableService = {}));
