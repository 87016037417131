"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerScope = void 0;
var PartnerScope;
(function (PartnerScope) {
  PartnerScope["SELF"] = "SELF";
  PartnerScope["PARTNER"] = "PARTNER";
})(PartnerScope || (exports.PartnerScope = PartnerScope = {}));
