"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EmailStatus = void 0;
var EmailStatus;
(function (EmailStatus) {
  EmailStatus["SENT"] = "SENT";
  EmailStatus["FAILED"] = "FAILED";
})(EmailStatus || (exports.EmailStatus = EmailStatus = {}));
