"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerCategory = void 0;
var PartnerCategory;
(function (PartnerCategory) {
  PartnerCategory["UPSTREAM"] = "UPSTREAM";
  PartnerCategory["DOWNSTREAM"] = "DOWNSTREAM";
})(PartnerCategory || (exports.PartnerCategory = PartnerCategory = {}));
