"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Status = void 0;
var Status;
(function (Status) {
  Status["NEW"] = "NEW";
  Status["COMPLETED"] = "COMPLETED";
  Status["FAILED"] = "FAILED";
})(Status || (exports.Status = Status = {}));
