"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Classification = void 0;
var Classification;
(function (Classification) {
  Classification["MANUFACTURING"] = "MANUFACTURING";
  Classification["WAREHOUSING"] = "WAREHOUSING";
  Classification["DISTRIBUTOR"] = "DISTRIBUTOR";
  Classification["RECYCLE"] = "RECYCLE";
})(Classification || (exports.Classification = Classification = {}));
