import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserService } from '@ieCore/services/user.service';

@Directive({
  selector: '[appHasPermission]',
  standalone: true,
})
export class HasPermissionDirective {
  @Input() set appHasPermission(permission: string | string[]) {
    this._permission = permission;
    this.updateView(permission).then();
  }

  @Input() set appHasPermissionRequireAll(value: boolean) {
    this._requireAllPermissions = value;
    this.updateView(this._permission).then();
  }

  _permission: string | string[] = '';
  _requireAllPermissions: boolean = false;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private userService: UserService,
  ) {}

  private async updateView(permission: string | string[]): Promise<void> {
    this.viewContainer.clear();

    if (
      await this.userService.hasPermission(
        permission,
        this._requireAllPermissions,
      )
    ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
