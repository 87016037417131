"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IndustryEnum = void 0;
var IndustryEnum;
(function (IndustryEnum) {
  IndustryEnum["AGRICULTURE"] = "AGRICULTURE";
  IndustryEnum["AUTOMOTIVE"] = "AUTOMOTIVE";
  IndustryEnum["BANKING_FINANCIAL_SERVICES"] = "BANKING_FINANCIAL_SERVICES";
  IndustryEnum["CONSTRUCTION"] = "CONSTRUCTION";
  IndustryEnum["CONSUMER_GOODS"] = "CONSUMER_GOODS";
  IndustryEnum["EDUCATION"] = "EDUCATION";
  IndustryEnum["ENERGY_UTILITIES"] = "ENERGY_UTILITIES";
  IndustryEnum["FOOD_BEVERAGE"] = "FOOD_BEVERAGE";
  IndustryEnum["HEALTHCARE"] = "HEALTHCARE";
  IndustryEnum["INFORMATION_TECHNOLOGY"] = "INFORMATION_TECHNOLOGY";
  IndustryEnum["MANUFACTURING"] = "MANUFACTURING";
  IndustryEnum["MINING_METALS"] = "MINING_METALS";
  IndustryEnum["PHARMACEUTICALS"] = "PHARMACEUTICALS";
  IndustryEnum["REAL_ESTATE"] = "REAL_ESTATE";
  IndustryEnum["RETAIL"] = "RETAIL";
  IndustryEnum["TELECOMMUNICATIONS"] = "TELECOMMUNICATIONS";
  IndustryEnum["TRANSPORTATION_LOGISTICS"] = "TRANSPORTATION_LOGISTICS";
  IndustryEnum["TRAVEL_HOSPITALITY"] = "TRAVEL_HOSPITALITY";
})(IndustryEnum || (exports.IndustryEnum = IndustryEnum = {}));
