"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportingPeriod = void 0;
var ReportingPeriod;
(function (ReportingPeriod) {
  ReportingPeriod["MONTHLY"] = "MONTHLY";
  ReportingPeriod["QUARTERLY"] = "QUARTERLY";
  ReportingPeriod["HALF_YEARLY"] = "HALF_YEARLY";
  ReportingPeriod["YEARLY"] = "YEARLY";
})(ReportingPeriod || (exports.ReportingPeriod = ReportingPeriod = {}));
